.question-card {
  position: relative;
  background-color: white;
  max-width: 900px;
  min-height: 40vh;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 70px -40px rgba(0, 0, 0, 0.75);
}

.question-card__title {
  margin-bottom: 5px;
}

.question-card__description {
  margin-bottom: 20px;
  color: rgb(136, 136, 136);
}

.question-card__answers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.question-card__choice-answer {
  padding: 10px;
  width: 120px;
  border: 1px rgb(196, 196, 196) solid;
  border-radius: 5px;
  margin: 0 10px;
  text-align: center;
}

.question-card__choice-answer:hover {
  cursor: pointer;
}

.question-card__choice-answer-normal:hover {
  background-color: rgb(247, 247, 247);
}

.question-card__choice-answer-active {
  background-color: rgb(215, 235, 255);
  border-color: rgb(166, 212, 254);
}

.question-card__choice-answer img {
  width: 80%;
  height: 80%;
}

.question-card__text-answer input {
  width: 80%;
  padding: 10px;
}
