.header-with-content-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  position: relative;
  margin-bottom: auto;
  justify-content: center;
}

.header-with-content-wrap > div > svg {
  user-select: none;
  margin: 18px 0;
  width: auto;
  height: 42px;
}

@media (max-width: 340px) {
  .header-with-content-wrap {
    padding: 0 8px;
  }

  .header-with-content-wrap > div > svg {
    margin-bottom: 9px;
    height: 40px;
  }
}

.header-with-content-wrap > p {
  font-size: 20px;
  color: #695849;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  margin-bottom: 9px;
}

.header-with-content-wrap > button {
  position: absolute;
  left: 0;
  height: 30px;
  width: 30px;
  border: 2px solid var(--oslo-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header-with-content-wrap button:focus {
  outline: none;
  box-shadow: 0px 0px 1px 3px #b9b9b9;
}

.header-with-content-wrap > button:nth-of-type(1) > img {
  margin-left: -2px;
}

.header-with-content-wrap > .cart-button {
  right: 0;
  left: unset;
  height: 30px;
  width: 32px;
  background: #f3795b;
  font-family: Montserrat;
  font-size: 14px;
  color: white;
  border: none;
}

.header-with-content-wrap > button:nth-of-type(2) > img {
  width: 90%;
}

.header-content > label {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: #695849;
}

.header-content > label + label {
  margin-top: 46px;
}

.header-content > label > input {
  margin-top: 13px;
  border-radius: 20px;
  background: #ececec;
  height: 33px;
  border: none;
  padding: 8px;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 16px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  left: 0;
  height: 17px;
  width: 17px;
  /* background-color: #facca4; */
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #f3b177;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #f3795b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
/* .container .checkmark:after { */
  /* left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); */
/* } */

.header-content > span {
  font-size: 13px;
  color: #cf9661;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 30px;
}

.desktop-cart-title,
.desktop-cart-buttons {
  display: none;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #91736f;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #91736f transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


@media only screen and (min-width: 768px) {
  .header-with-content-wrap > div > svg {
    margin: 4px;
  }
}

@media only screen and (min-width: 640px) {
  .header-with-content-wrap > div > svg {
    margin-top: 0;
    margin-bottom: 12px;
    height: 60px;
  }

  .header-with-content-wrap > button {
    display: none;
  }
}
