.input-container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px;
  justify-content: center;
}

@media (min-width: 340px) {
  .input-container {
    margin: 10px 0px;
  }
}


.input-container > input {
  background: #ECECEC;
  height: 33px;
  border: 1px solid var(--oslo-gray);
  padding: 8px 11px;
  width: 80%;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: var(--outer-space);
  font-family: Roboto;
  text-align: center;
}

.input-container > input::placeholder {
  color: #846953e0;
  opacity: 1;
}

.input-container > input:focus {
  outline: none;
  box-shadow: 0px 0px 1px 1px #a2a2a296;
}

.input-container > span {
  position: absolute;
  right: 40px;
  border: 1px solid;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  /* padding: 5px;  top: auto; */
  /* bottom: 5px; */

}
