.answers-progress-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  height: 25px;
}

.answers-progress-wrap > .points {
  display: flex;
  list-style: none;
  align-items: center;
}

.answers-progress-wrap > .points > li {
  height: 7px;
  width: 7px;
  background-color: var(--oslo-gray);
}

.answers-progress-wrap > .points > li + li {
  margin-left: 16px;
}

.answers-progress-wrap > .points > li.active {
  height: 8px;
  width: 8px;
  background-color: var(--apricot);
}

.answers-progress-wrap > span {
  font-size: 12px;
  font-weight: 500;
  color: #695849;
  margin-top: 7px;
}
