#ingredients-header {
  padding: 0 10px;
}

.ingredients-wrap {
  overflow: hidden;
  height: 100%;
  background-color: white;
  width: 100%;
}

.ingredients-text-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.ingredients-header {
  color: var(--outer-space);
  font-family: Montserrat;
  font-size: 21px;
  font-weight: bold;
  text-transform: UPPERCASE;
  padding: 25px 15px 20px;
  text-align: center;
  background-color: white;
}

.ingredients-sub{
  color: var(--outer-space);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  text-transform: UPPERCASE;
  padding: 8px 15px 20px;
  text-align: center;
  background-color: white;
}

.user-name {
  color: var(--apricot);
}

.desktop-cart-button {
  display: none;
}

@media only screen and (min-width: 400px) {
  .ingredients-wrap > h1 {
    font-size: 18px;
  }
}

.ingredients-wrap > .ingredients-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 0 30px;
}

.ingredients-item {
  background-image: linear-gradient(var(--outer-space), var(--outer-space)),
    linear-gradient(var(--outer-space), var(--outer-space)),
    linear-gradient(var(--outer-space), var(--outer-space)),
    linear-gradient(var(--outer-space), var(--outer-space));
  background-repeat: no-repeat;
  background-size: 1px 8px;
  background-position: top left, top right, bottom left, bottom right;
  border-bottom: solid var(--outer-space);
  border-width: 1px 0;
  display: grid;
  padding: 10px 0;
}

.ingredients-item:first-of-type {
  border-top: solid var(--outer-space);
  border-width: 1px 0;
}

.ingredients-item > h2 {
  font-size: 18px;
  color: #f3795b;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  grid-column: 2;
  padding: 0 10px;
}

.ingredients-item > p {
  font-size: 14px;
  color: var(--outer-space);
  line-height: 16px;
  text-align: left;
  grid-column: 2;
  padding-top: 9.5px;
  padding-bottom: 10px;
}

.ingredients-wrap > .ingredients-item > div:first-of-type {
  margin-right: 10px;
}

.ingredients-item > img {
  width: 104px;
  height: auto;
  margin: 0 9px;
  grid-row: 2;
  margin-bottom: 5px;
}

.ingredients-wrap::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
.ingredients-wrap::-webkit-scrollbar-thumb {
  background: #ff0000;
}

/* @media only screen and (max-height: 700px) {
  #ingredients-header {
    height: 75%;
  }
} */

@media only screen and (max-height: 750px) {
  .ingredients-item > .text-content > p {
    font-size: 13px;
  }

  .ingredients-item > .text-content > h2 {
    font-size: 16px;
  }

  .ingredients-wrap > .ingredients-item > div > img {
    width: 55px;
  }
}

@media only screen and (min-width: 640px) {

  #ingredients-header > .ingredients-wrap > h1 {
    text-align: center;
    margin: 30px;
    font-size: 22px;
  }

  #ingredients-header > .ingredients-wrap > ul {
    display: flex;
    height: 80%;
    margin: 0;
    justify-content: center;
  }

  #ingredients-header > .ingredients-wrap > ul > li {
    border: 2px solid rgb(243, 121, 91, 0.74);
    border-radius: 40px;
    height: 100%;
    width: 20%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 4px;
  }

  #ingredients-header > .ingredients-wrap > ul > li:last-of-type {
    margin-right: 0;
  }

  .ingredients-wrap > ul > li > div:first-of-type {
    display: flex;
    align-self: center;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 110px;
    border: 2px solid rgb(243, 121, 91, 0.74);
    flex-shrink: 0;
    margin-top: 20px;
    margin-right: 0;
  }

  .ingredients-wrap > ul > li > div > img {
    max-width: 100%;
    max-height: 100%;
    width: unset !important;
    padding: 10px;
  }

  .ingredients-wrap > ul > li > .text-content {
    height: 100%;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }

  ul.ingredients-item > .text-content > h2 {
    font-size: 20px;
    margin-top: 12px;
  }

  ul.ingredients-item > .text-content > p {
    font-size: 16px;
  }

  .ingredients-header-wrap {
    background: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 25px 35px 0;
  }

  .ingredients-header {
    width: 100%;
    padding: 0 14%;
  }

  .ingredients-header-wrap > button {
    position: relative;
    cursor: pointer;
    border: 2px solid var(--oslo-gray);
    background: none;
    width: 36px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ingredients-header-wrap > .desktop-cart-button {
    height: 30px;
    width: 36px;
    background: #f3795b;
    font-family: Montserrat;
    font-size: 14px;
    color: white;
    border: none;
  }

  .ingredients-wrap .ingredients-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .ingredients-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
    border-top: solid var(--outer-space);
    border-width: 1px 0;
    height: 42%;
    margin: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    min-height: 173px;
  }

  @media (max-width: 700px) {
    .ingredients-item > img {
      width: 64px;
    }
  }

  .ingredients-item > h2 {
    font-size: 16px;
  }

  .ingredients-item > p {
    text-align: center;
  }

}

@media only screen and (max-width: 320px) {
  .ingredients-item > h2 {
    text-align: center;
    grid-column: 1 / 2 span;
  }
}

@media only screen and (min-width: 768px) and (max-height: 800px) {

  #ingredients-header > .ingredients-wrap > h1 {
    font-size: 20px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  #ingredients-header > .ingredients-wrap > ul {
    height: 85%;
    margin: 0;
    max-height: 100%;
  }

  #ingredients-header > .ingredients-wrap > ul > li {
    width: 25%;
    margin-right: 2%;
    border-radius: 30px;
  }

  #ingredients-header
    > .ingredients-wrap
    > .ingredients-content
    > li
    > div:first-of-type {
    width: 70px;
    height: 70px;
    margin-right: 0;
    margin-top: 8px;
  }

  .ingredients-wrap > ul > li > div > img {
    padding: 6px;
  }

  .ingredients-wrap > ul > li > .text-content {
    width: 100%;
  }

  .ingredients-wrap > ul.ingredients-item > .text-content > h2 {
    margin-top: 8px;
    font-size: 16px;
    word-break: break-word;
  }

  ul.ingredients-item > .text-content > p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  .header-with-content-wrap.ingredients-wrap .desktop-cart-buttons {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -5px;
    right: -5px;
    left: unset;
  }

  .header-with-content-wrap.ingredients-wrap .desktop-cart-buttons > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    background: #f3795b;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }

  .header-with-content-wrap.desktop-ingredients-wrap .desktop-cart-buttons
    > button:nth-child(2) {
    padding: 8px;
  }

  .header-with-content-wrap.desktop-ingredients-wrap .desktop-cart-buttons
    > button
    + button {
    margin-top: 24px;
  }

  .header-with-content-wrap.ingredients-wrap .desktop-cart-buttons > button > img {
    max-width: 75%;
  }

  .header-with-content-wrap.desktop-ingredients-wrap > svg {
    margin: 0;
    position: absolute;
    top: -3px;
    left: 8%;
  }
}

@media (min-width: 640px) {
  .desktop-ingredients-wrap > svg {
    align-self: flex-start;
    margin-left: 5%;
    /* margin-top: -5px; */
    margin-bottom: 20px;
  }
}
