@media only screen and (min-width: 768px) {
  .modal-wrap {
    align-items: unset !important;
  }

  .modal-wrap > .modal-body {
    position: relative !important;
    top: 5% !important;
  }

  .modal-wrap > .modal-body.only-text {
    width: 30% !important;
    height: fit-content;
    top: 20% !important;
  }

  .modal-wrap > .modal-body.only-text > .description-modal-content > h1 {
    font-size: 16px;
    top: 10px;
  }

  .modal-wrap > .modal-body.only-text .description-item.text {
    width: 100%;
    padding: 15px 0;
    margin: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-wrap > .modal-body.only-text .description-item.text > .desktop-description-item > .header {
    display: none;
  }

  .modal-wrap
    > .modal-body.only-text
    .description-item.text
    > .desktop-description-item
    > .content
    > p {
    font-size: 14px;
  }

  .modal-wrap > .modal-body.no-header > header {
    padding: 0;
  }

  .modal-wrap > .modal-body.only-text.two-items {
    /* height: 50% !important; */
    height: min-content !important;
    padding: 11px 12px 22px 12px;
  }

  .modal-wrap > .modal-body.only-text.two-items > .modal-content {
    padding: 0 20px;
  }

  .modal-wrap > .modal-body.only-text.two-items .description-item.text {
    height: unset;
  }

  .modal-wrap
    > .modal-body.only-text.two-items
    .description-item.text
    > .desktop-description-item
    > .header {
    display: flex;
    width: 100%;
  }

  .modal-wrap
    > .modal-body.only-text.two-items
    .description-item.text
    > .desktop-description-item
    > .header
    > .title-wrap {
    margin-top: 0;
  }

  .modal-wrap
    > .modal-body.only-text.two-items
    .description-item.text
    > .desktop-description-item
    > .content
    > p {
    text-align: center;
  }
}
/* 
@media only screen and (min-width: 768px) and (max-height: 800px) {
  .modal-wrap > .modal-body {
    height: 80% !important;
    width: 50% !important;
  }
} */
