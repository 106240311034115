@media only screen and (min-width: 768px) {
  .answer-wrap {
    font-size: 20px;
  }

  .form-wrap.ten-answers .multi-choice-answer-wrap > .answer-wrap > p {
    font-size: 13px !important;
  }

  /* .form-wrap.ten-answers .multi-choice-answer-wrap > .answer-wrap > img {
    height: 50% !important;
  } */
}

@media only screen and (min-width: 768px) and (max-height: 800px) {
  .answer-wrap {
    width: 18%;
  }

  .answer-wrap > p {
    font-size: 16px;
    color: var(--outer-space);
    font-family: Montserrat;
  }

  .form-wrap .single-choice-answer-wrap.desktop-many-question .answer-wrap > p,
  .form-wrap.many-answers .multi-choice-answer-wrap .answer-wrap > p {
    font-size: 14px !important;
  }

  .form-wrap.five-answers .single-choice-answer-wrap .answer-wrap,
  .form-wrap.five-answers .multi-choice-answer-wrap .answer-wrap {
    width: 25% !important;
    height: 45% !important;
    margin-right: 5% !important;
  }
}

/* @media only screen and (min-width: 768px) and (min) */
