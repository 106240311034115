@keyframes loading {
  0%,
  40%,
  100% {
    transform: scale(0.4);
  }
  20% {
    transform: scale(0.7);
  }
}

.loading-animation-wrap > ul {
  display: flex;
  justify-content: center;
}

.loading-animation-wrap > ul > li {
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.97);
  animation: loading 1.6s infinite;
}

.loading-animation-wrap > ul > li:nth-child(1) {
  animation-delay: -1.4s;
}

.loading-animation-wrap > ul > li:nth-child(2) {
  animation-delay: -1.2s;
}

.loading-animation-wrap > ul > li:nth-child(3) {
  animation-delay: -1s;
}

.loading-animation-wrap > ul > li:nth-child(4) {
  animation-delay: -0.8s;
}
