.icon-button {
  height: 32px;
  width: 36px;
  border: 2px solid var(--oslo-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--oslo-gray);
  outline: 0;
  /* box-shadow: 0 0 0 0 rgba(90, 153, 212, .5) */
}

.icon-button > img {
  height: 23px;
}

.icon-button.pulse {
  animation: pulse 1.5s infinite;
}

.icon-button:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(243, 121, 91, 0.5);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(243, 121, 91, 0);
  }
}
