.user-email-wrap {
  height: 100%;
  width: 100%;
  background: white;
}

.email-page-title {
  color: var(--outer-space);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  text-transform: UPPERCASE;
  background: white;
  text-align: center;
  padding: 25px 25px 15px;
}

.user-email-wrap > label {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: var(--apricot);
  text-align: center;
  font-family: Roboto;
  font-weight: bold;
  text-transform: uppercase;
}

.user-email-wrap > label + label {
  margin-top: 30px;
}

.user-email-wrap > label > input {
  margin-top: 12px;
  width: 80%;
  background: #ececec;
  height: 33px;
  border: 1px solid var(--oslo-gray);
  padding: 8px 12px;
  text-align: center;
}

.user-email-wrap > label > input:focus {
  outline: none;
  box-shadow: 0 0 1px 1px #c5a491;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 16px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  left: 0;
  height: 17px;
  width: 17px;
  background: white;
  border: solid 2px var(--apricot);
  padding: 1px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #f3b177;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #f3795b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.user-email-wrap > span {
  font-size: 13px;
  color: var(--oslo-gray);
  font-family: Roboto;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

@media only screen and (min-width: 640px) {
  .user-email-wrap > button {
    position: relative;
    top: 30px;
    left: 36px;
    cursor: pointer;
    border: 2px solid var(--oslo-gray);
    background: none;
    width: 32px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .email-page-title {
    width: 100%;
    padding: 0 30% 30px;
  }

  .user-email-wrap > label > input {
    width: 50%;
  }

}

@media only screen and (max-height: 700px) {
  .user-email-wrap > label + label {
    margin-top: 25px;
  }

  .user-email-wrap > label {
    font-size: 18px;
  }

  .user-email-wrap > span {
    font-size: 14px;
    margin-top: 16px;
  }
}

@media only screen and (max-width: 340px) {
  .user-email-wrap > label + label {
    margin-top: 10px;
  }

  .email-page-title {
    padding: 10px 10px 15px;
  }

  .user-email-wrap > label {
    font-size: 15px;
    padding: 0 10px;
  }
}


@media only screen and (max-height: 600px) {
  .user-email-wrap > span {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

@media only screen and (min-width: 768px) {
  .user-email-wrap > label {
    padding: 20px 28px;
  }

  .user-email-wrap > label + label {
    margin-top: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 800px) {
  .user-email-wrap > label {
    border-radius: 24px;
    padding: 10px 20px;
    font-size: 16px;
  }

  .user-email-wrap > label > input {
    margin-top: 8px;
  }

  .user-email-wrap > label + label {
    margin-top: 3px;
  }

  .user-email-wrap > label > .container {
    margin-top: 8px;
    font-size: 16px;
  }

  .user-email-wrap > span {
    font-size: 13px;
    margin: 12px 0;
  }

  .form-wrap.user-email > div:last-of-type {
    margin-top: auto !important;
  }
}

.user-email > .header-with-content-wrap {
  min-height: fit-content;
}
