.home-page-wrap {
  height: 100%;
  background-color: var(--gallery);
}

.home-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  height: 100%;
  align-items: center;
}

.welcome-header-wrap {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  position: relative;
  padding-bottom: 22px;
  background-color: white;
}

.welcome-header-wrap svg {
  padding: 4px;
  user-select: none;
  width: 185px;
  height: auto;
}

.home-page-content .home-page-title {
  font-family: Montserrat;
  font-size: 20pt;
  color: var(--outer-space);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  background-color: white;
  padding: 0 25px;
  width: 100%;
}

.home-page-wrap .home-page-highlight {
  color: #f3795b;
  font-family: Roboto;
  font-size: 18px;
  padding-top: 10px;
  width: 100%;
  text-align: center;
  background-color: white;
  text-transform: uppercase;
}

.home-page-carousel {
  width: 100%;
  height: auto;
  position: relative;
  padding: 20px 25px;
  text-align: center;
  background-color: white;
}

.home-page-footer {
  width: 100%;
  padding-bottom: 25px;
  background-color: white;
  display: flex;
  justify-content: center;
}

@media (max-width: 640px) {
  .home-page-footer .primary-button {
    width: 50%;
  }
}

@media only screen and (max-width: 400px) {
  .home-page-content .home-page-title {
    font-size: 16pt;
  }

  .home-page-content > .welcome-header-wrap > svg {
    width: 110px;
  }
}

@media only screen and ( max-width: 320px) {
  .welcome-header-wrap svg {
    width: 150px;
  }

  .welcome-header-wrap > .home-page-title {
    font-size: 13pt;
  }

  .home-page-wrap .home-page-highlight {
    font-size: 13pt;
  }

  .home-page-carousel {
    padding: 0;
  }
}

@media only screen and ( max-width: 320px) and (max-height: 500px) {
  .carousel-steps-indicator {
    margin-top: 0;
  }

  .welcome-header-wrap > .home-page-title {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  .home-page-wrap > .home-page-content > .header-wrap {
    height: 35%;
  }

  .home-page-wrap > .home-page-content > .primary-button {
    width: 30%;
    margin-bottom: 0;
  }
}



.home-page-tutorial {
  max-width: 900px;
}

.tutorial-wrap {
  display: none;
}

@media only screen and (min-width: 1000px) {
  .home-page-content {
    padding-bottom: 70px;
  }

  .welcome-header-wrap {
    width: 900px;
    padding: 22px 16px;
  }

  .home-page-footer {
    width: 900px;
  }

  .home-page-carousel {
    display: none;
  }

  .tutorial-wrap {
    display: flex;
    padding: 30px 50px;
    justify-content: space-evenly;
    width: 100%;
    background-color: white;
  }

  .tutorial-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 300px;
  }

  .tutorial-item > .tutorial-step-number {
    position: absolute;
    top: 0;
    left: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: var(--outer-space);
  }

  .tutorial-item svg {
    width: 158px;
  }

  .tutorial-item > .tutorial-text {
    text-align: center;
    width: 80%;
    font-size: 18px;
    color: var(--outer-space);
    top: 10px;
    font-family: 'Roboto';
  }

  .home-page-footer {
    padding-bottom: 35px;
    padding-top: 22px;
  }

  .home-page-footer .primary-button {
    width: 25%;
  }

}

.icons-color *{
  fill: var(--outer-space);
}
