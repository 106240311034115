.header-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  position: relative;
}

.header-wrap > svg {
  user-select: none;
  width: auto;
  height: 42px;
  margin: 18px 0;
}

.header-wrap > p {
  font-size: 18px;
  color: #695849;
  font-weight: 500;
  text-align: center;
  padding: 15px 0;
}

.header-wrap > button {
  position: absolute;
  left: 0;
  height: 30px;
  width: 30px;
  border: 2px solid var(--oslo-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header-wrap > button:focus {
  outline: none;
  box-shadow: 0px 0px 1px 3px #b9b9b9;
}

.header-wrap > button > img {
  margin-left: -2px;
}

@media only screen and (max-height: 620px) {
  /* .header-wrap > p {
    font-size: 16px;
  } */

  .header-wrap > svg {
    margin: 3px 0;
  }
}

@media only screen and (max-height: 700px) {
  .header-wrap > p {
    font-size: 14px;
    padding: 0px 0 12px 0;
  }
}

@media only screen and (max-height: 500px) {
  .header-wrap > svg {
    margin: 3px 0;
  }
}

@media only screen and (min-width: 768px) {
  .header-wrap > p {
    font-size: 16px;
  }
  .header-wrap > svg {
    margin-top: 0;
    margin-bottom: 12px;
    height: 60px;
  }
}

@media only screen and (min-width: 640px) {
  .header-wrap .hide-on-desktop {
    display: none;
  }
}

@media only screen and (min-width: 1000px) and (max-height: 664px) {
  .header-wrap > svg {
    height: 40px;
  }
}
