.single-choice-answer-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  width: 100%;
}

@media (max-width: 340px)  {
  .single-choice-answer-wrap {
    grid-row-gap: 4px;
  }
}

@media (min-width: 640px)  {
  .single-choice-answer-wrap {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
  }

  .single-choice-answer-wrap.desktop-many-question {
    width: 100%;
    flex-wrap: wrap;
  }
}
