.answer-wrap {
  background-color: #fff;
  width: 130px;
  height: 177px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  outline: 0;
}

.answer-wrap.hidden-title {
  height: fit-content;
}

.answer-text {
  font-family: Roboto;
  text-align: center;
  font-weight: normal;
  text-transform: UPPERCASE;
  color: var(--outer-space);
  font-size: 15px;
}

.answer-text-wrap {
  height: 100%;
  display: flex;
  align-items: center;
}

.answer-wrap.active .answer-text {
  color: var(--apricot);
  font-weight: bold;
  transition: 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.image-wrap {
  height: 125px;
}

.image-wrap > svg {
  user-select: none;
  -webkit-user-drag: none;
  height: 115px;
}

.answer-wrap.active svg * {
  fill: var(--apricot);
  transition: 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  stroke-width: 1px;
  stroke: #F4A27E;
}

/* .answer-wrap.min {
  width: 125px;
  height: 155px;
} */

/* .answer-wrap.min > svg {
  height: 90px;
} */

.answer-wrap.min .answer-text {
  font-size: 16px;
}

@media only screen and (max-height: 690px) {
  .answer-wrap {
    width: 120px;
    height: 140px;
    padding: 6px;
  }

  .image-wrap > svg {
    height: 85px;
  }

  .answer-wrap .answer-text {
    font-size: 14px;
  }

  .answer-wrap.min {
    width: 120px;
    height: 150px;
  }

  .answer-wrap.min .answer-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 415px) and (max-height: 740px) {
  .answer-wrap {
    width: 140px;
    height: 165px;
    padding: 6px;
  }

  .image-wrap > svg {
    height: 85px;
  }

  .answer-wrap > p {
    font-size: 14px;
  }

  /* .answer-wrap.min {
    width: 120px;
    height: 145px;
  } */

  /* .answer-wrap.min > svg {
    height: 80px;
  } */

  .answer-wrap.min .answer-text {
    font-size: 14px;
  }
}

@media only screen and (max-height: 750px) {
  .answer-wrap {
    width: 130px;
    height: 170px;
    padding: 6px;
  }

  .image-wrap > svg {
    height: 115px;
  }

  /* .answer-wrap.min {
    width: 120px;
    height: 150px;
  } */

  .answer-wrap.min .answer-text {
    font-size: 14px;
  }
}

@media only screen and (max-height: 700px) {
  .answer-wrap {
    width: 130px;
    height: 145px;
    padding: 6px;
  }

  /* .image-wrap > svg {
    height: 85px;
  } */

  .answer-wrap .answer-text {
    font-size: 14px;
  }

  .answer-wrap.min {
    width: 130px;
    height: 145px;
  }

  .answer-wrap.min .answer-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 310px) {
  .answer-wrap {
    width: 120px;
    height: 140px;
  }

  .image-wrap > svg {
    height: 80px;
  }

  .answer-wrap.min {
    width: 110px;
    height: 140px;
  }

  .answer-wrap.min > svg {
    height: 75px;
  }

  .answer-wrap.min .answer-text {
    font-size: 13px;
  }
}

@media (min-width: 640px) {
  .answer-wrap {
    width: 15%;
    height: 200px;
  }

  .image-wrap > svg {
    height: 110px;
    margin-top: 0;
  }

  .answer-wrap .answer-text {
    font-size: 16px;
  }

  .answer-wrap.min {
    width: 15%;
  }

  .answer-wrap.min > svg {
    height: 110px;
    margin-top: 12px;
  }

  .answer-wrap.min .answer-text {
    font-size: 16px;
  }

  .answer-wrap.min.many-answers {
    height: 45%;
    margin-right: 10%;
  }
}

@media only screen and (max-height: 620px) {
  .answer-wrap {
    width: 125px;
    height: 135px;
    padding: 6px;
  }

  .image-wrap > svg {
    height: 80px;
  }

  .answer-wrap .answer-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 330px) {
  .answer-wrap {
    width: 110px;
    height: 120px;
  }

  .answer-wrap.min {
    height: 120px;
    width: 130px;
  }
}

@media only screen and (min-width: 768px) {
  .answer-wrap {
    width: 20%;
    min-height: 120px;
  }

  /* .image-wrap > svg {
    height: 75%;
  } */

  .form-wrap.five-answers .single-choice-answer-wrap .answer-wrap,
  .form-wrap.five-answers .multi-choice-answer-wrap .answer-wrap {
    width: 25% !important;
    margin-right: 5% !important;
  }

  /* .form-wrap.ten-answers .multi-choice-answer-wrap .answer-wrap > svg,
  .form-wrap.five-answers .single-choice-answer-wrap .answer-wrap > svg,
  .form-wrap.five-answers .multi-choice-answer-wrap .image-wrap > svg {
    height: 60% !important;
  } */

  .form-wrap.five-answers .answer-wrap:last-of-type,
  .form-wrap.five-answers .answer-wrap:nth-child(3) {
    margin-right: 0 !important;
  }

  .form-wrap.ten-answers .multi-choice-answer-wrap .answer-wrap {
    width: 19% !important;
    margin-right: 1% !important;
  }

  .form-wrap.ten-answers .multi-choice-answer-wrap .answer-wrap .answer-text {
    font-size: 16px !important;
  }
}
