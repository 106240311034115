.modal-wrap {
  position: absolute;
  background: var(--gallery);
  height: 100%;
  width: 100vw;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.modal-wrap > .modal-body {
  box-shadow: 1px 1px 20px #00000021;
  background: white;
  max-height: 90%;
  width: 90vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 23px;
}

.modal-wrap > .modal-body > header {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 30px;
  min-height: 50px;
}

.modal-wrap > .modal-body > header > button {
  border: none;
  background: none;
  display: flex;
  outline: 0;
}

@media only screen and (max-height: 460px) {
  .modal-wrap > .modal-body > header > button {
    padding-bottom: 25px;
  }
}

.modal-body > .modal-content {
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;
  overflow-y: auto;
}

.description-itens::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
/* .modal-content::-webkit-scrollbar-thumb {
  background: #ff0000;
} */

.icon-close-modal:hover path {
  fill: #b1b1b1;
}
