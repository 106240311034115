.modal-content.description-modal-content {
  overflow: hidden;
  padding: 0;
}

.description-modal-content > h1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--outer-space);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 30px;
  padding: 0 15px;
}

.description-modal-content > .description-itens {
  overflow-y: auto;
  padding: 0 30px;
}

.description-modal-content > .description-itens::-webkit-scrollbar {
  width: 4px;
}

.description-item {
  display: inline-block;
  background-image: linear-gradient(var(--brackets-color), var(--brackets-color)),
    linear-gradient(var(--brackets-color), var(--brackets-color)),
    linear-gradient(var(--brackets-color), var(--brackets-color)),
    linear-gradient(var(--brackets-color), var(--brackets-color));
  background-repeat: no-repeat;
  background-size: 1px 8px;
  background-position: top left, top right, bottom left, bottom right;
  border-bottom: solid var(--brackets-color);
  border-width: 1px 0;
}

.description-item:first-of-type {
  border-top: solid var(--brackets-color);
  border-width: 1px 0;
}

.description-content {
  display: grid;
  margin: 10px 0;
}

.description-item.text {
  padding: 10px 0;
}

.description-item > div > h2 {
  font-size: 18px;
  color: #f3795b;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  grid-column: 2;
}

.description-item > div >  p {
  font-size: 14px;
  color: var(--outer-space);
  line-height: 16px;
  text-align: left;
  grid-column: 2;
  padding-top: 9.5px;
  padding-bottom: 10px;
}

.description-item > div > svg {
  width: 84px;
  height: 84px;
  margin: 0 9px;
  grid-row: 2;
  margin-bottom: 5px;
} 

.desktop-description-item {
  display: none;
}

@media (min-width: 640px) {
  .modal-wrap {
    align-items: unset;
  }

  .modal-wrap > .modal-body {
    height: 70%;
    width: 60%;
    position: relative;
    top: 5%;
  }

  .modal-wrap > .modal-body > header {
    padding: 30px;
  }

  .modal-wrap > .modal-body > header > button {
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .modal-body > .modal-content {
    height: 100%;
    padding: 0px 30px 0px 30px;
  }

  .modal-body > .modal-content > .description-itens {
    flex-wrap: wrap;
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;
  }

  .modal-content > h1 {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 15px);
    width: 100%;
  }

  .only-text h1 {
    left: 47%;
    padding: 0 60px;
  }

  .only-text .description-itens {
    padding: 25px 30px;
  }

  .description-item {
    /* height: 35%; */
    width: 47%;
    padding: 10px 20px;
    margin: 3% 2%;
    overflow: hidden;
    border-top: solid var(--brackets-color);
    border-width: 1px 0;
    display: flex;
    align-items: center;
  }

  .description-item.text + .description-item.text {
    border-top: none;
  }

  .desktop-description-item {
    display: block !important;
    height: 100%;
    overflow: auto;
  }

  .desktop-description-item > .header {
    width: 80%;
    display: flex;
    align-items: center;
  }

  .desktop-description-item > .header > .svg-wrap {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    flex-shrink: 0;
  }

  .header > .svg-wrap > svg {
    max-width: 100%;
    max-height: 100%;
  }

  .desktop-description-item > .header > .title-wrap {
    width: 100%;
    margin-top: -15px;
  }

  .header > .title-wrap > h2 {
    justify-self: center;
    text-align: center;
    font-size: 17px !important;
    font-weight: normal;
    color: #f3795b;
    margin-bottom: 8px;
  }

  .desktop-description-item > .content {
    margin-top: 15px;
  }
}

.icons-color *{
  fill: var(--apricot);
}
