.carousel-content {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding: 3px 0px;
}

.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > .carousel-item {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  scroll-snap-align: center;
  bottom: 0;
  flex-direction: column;
  position: relative;
}

p.carousel-step-number {
  position: absolute;
  top: 0;
  left: 25px;
  font-family: Montserrat;
  font-size: 25px;
  color: var(--outer-space);
}

.carousel-item svg {
  width: 145px;
  height: 125px;
}

.carousel-item .carousel-text {
  text-align: center;
  width: 80%;
  font-size: 17px;
  color: var(--outer-space);
  top: 10px;
  font-family: 'Roboto';
}

.carousel-steps-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.carousel-steps-indicator button {
  width: 8.68px;
  height: 8.68px;
  background: var(--oslo-gray);
  position: relative;
  margin: 5px;
  border: hidden;
  outline: none;
  cursor: pointer;
  transition: width 0.2s, height 0.2s, margin 0.2s, background 0.5s;
}

.carousel-steps-indicator > .active-button {
  width: 15px;
  height: 15px;
  margin: 0 7px;
  background: var(--apricot);
}

.carousel-left-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  top: calc(50% - 12.5px);
  background-color: white;
  border: 2px solid var(--oslo-gray);
  outline: none;
  z-index: +1;
}

.carousel-left-button svg {
  width: 15px;
  height: 15px;
}

.carousel-right-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0;
  top: calc(50% - 12.5px);
  background-color: white;
  border: 2px solid var(--oslo-gray);
  outline: none;
  z-index: 1;
}

.carousel-right-button svg {
  width: 15px;
  height: 15px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.carousel-side-buttons button {
  cursor: pointer;
}

.carousel-side-buttons > .inactive-side-button {
  display: none;
}

.carousel-right-button-wrap, .carousel-left-button-wrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 60%;
  top: calc(50% - 30%);
}

.carousel-right-button-wrap {
  right: 25px;
}

.carousel-left-button-wrap {
  left: 25px;
}

@media (max-height: 454px) {
  .carousel-item svg {
    width: 108px;
    height: auto;
  }
  .carousel-steps-indicator {
    margin-top: 0;
  }
}

.icons-color *{
  fill: var(--outer-space) !important;
}
