@media only screen and (min-width: 768px) and (max-height: 664px) {
  .form-wrap {
    height: 80% !important;
    /* width: 55% !important; */
  }

  .form-wrap.many-answers {
    height: 90% !important;
  }

  .form-wrap.formula-name > div:last-of-type {
    margin-top: auto !important;
  }
}
