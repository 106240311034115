.form-wrap {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 5vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div[role="presentation"] {
  display: none;
}

div[role="presentation"] div {
  bottom: 8px !important;
}

div[role="presentation"] > iframe {
  display: none !important;
}

.title-wrap {
  width: 100%;
}

.question-wrap {
  height: 100%;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  display: flex;
}

.answers-wrap {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  flex-direction: column;
}

.question-title {
  color: var(--outer-space);
  font-family: Montserrat;
  font-size: 21px;
  font-weight: bold;
  text-transform: UPPERCASE;
  text-align: center;
  padding: 15px 15px 15px;
  background-color: white;
  width: 100%;
}

@media only screen and (max-height: 500px) {
  .question-title {
    padding: 10px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 300px) {
  .question-title {
    font-size: 15px;
  }
}

@media only screen and (max-width: 639px) and (max-height: 540px) {
  .answers-wrap {
    align-items: flex-start !important;
  }
}

.form-wrap .form-actions {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-top: 24px;
  user-select: none;
}

@media (max-width: 640px) {
  .footer-center-actions > .primary-button {
    width: 50%;
  }
}

.animated-div {
  position: absolute;
  width: 100%;
  height: 100%;
}

.icon-button {
  cursor: pointer;
  background: none;
}


.animated-div {
  background-color: var(--gallery);
}

@media (min-width: 768px) {
  .form-wrap {
    padding: 0 40px;
    margin: 30px auto;
    height: 70%;
    max-width: 960px;
    max-height: 760px;
  }

  .form-wrap > .form-actions {
    margin-bottom: 30px;
    justify-content: normal;
    display: flex;
  }

  .form-actions {
    display: flex;
  }

  /* FIVE ANSWERS */
  .form-wrap.five-answers .single-choice-answer-wrap {
    width: 60%;
    padding: 0 !important;
  }

  .form-wrap.five-answers .single-choice-answer-wrap .answer-wrap {
    width: 20%;
    margin-right: 10%;
  }

  .form-wrap.five-answers .single-choice-answer-wrap .answer-wrap > p {
    font-size: 18px;
  }

  .form-wrap.five-answers .single-choice-answer-wrap .answer-wrap > img {
    height: 105px;
  }

  .form-wrap.five-answers .multi-choice-answer-wrap {
    width: 60%;
    padding: 0;
  }

  .form-wrap.five-answers .multi-choice-answer-wrap .answer-wrap {
    width: 20%;
    height: 45%;
    margin-right: 10%;
  }

  .form-wrap.five-answers .multi-choice-answer-wrap .answer-wrap > p {
    font-size: 18px;
  }

  .five-answers .answer-wrap:last-of-type,
  .five-answers .answer-wrap:nth-of-type(3) {
    margin-right: 0 !important;
  }

  /* End FIVE ANSWERS */

  /* Seven Answers */
  .form-wrap.seven-answers .single-choice-answer-wrap {
    width: 70%;
    padding: 0 !important;
  }

  .form-wrap.seven-answers .single-choice-answer-wrap .answer-wrap {
    width: 20%;
    margin-right: 5%;
  }

  .form-wrap.seven-answers .single-choice-answer-wrap .answer-wrap > p {
    font-size: 18px;
  }

  .form-wrap.seven-answers .multi-choice-answer-wrap {
    width: 70%;
    padding: 0;
    justify-content: center;
    grid-row-gap: 20px;
  }

  @media (min-height: 701px) {
    .form-wrap.seven-answers .multi-choice-answer-wrap {
      grid-row-gap: 0;
    }
  }

  .form-wrap.seven-answers .multi-choice-answer-wrap .answer-wrap {
    width: 20%;
    margin-right: 5%;
  }

  .form-wrap.seven-answers .multi-choice-answer-wrap .answer-wrap > p {
    font-size: 18px;
  }

  .seven-answers .answer-wrap:last-of-type,
  .seven-answers .answer-wrap:nth-of-type(4) {
    margin-right: 0 !important;
  }

  /* End Seven Answers */

  /* Eight Answers */

  .form-wrap.eight-answers .single-choice-answer-wrap.desktop-many-question {
    width: 70%;
  }

  .form-wrap.eight-answers .answer-wrap.min {
    padding: 0;
    min-width: 132px;
  }

  /* End Seven Answers */

  /* Six Answers */
  .form-wrap.six-answers .single-choice-answer-wrap {
    width: 55%;
    padding: 0 !important;
  }

  .form-wrap.six-answers .single-choice-answer-wrap .answer-wrap {
    width: 25%;
    height: 45%;
    margin-right: 5%;
  }

  .form-wrap.six-answers .single-choice-answer-wrap .answer-wrap > p {
    font-size: 18px;
  }

  .form-wrap.six-answers .multi-choice-answer-wrap {
    width: 55%;
    padding: 0;
    justify-content: center;
  }

  .form-wrap.six-answers .multi-choice-answer-wrap .answer-wrap {
    width: 25%;
    height: 45%;
    margin-right: 5%;
  }

  .form-wrap.six-answers .multi-choice-answer-wrap .answer-wrap > p {
    font-size: 18px;
  }

  .form-wrap.six-answers .multi-choice-answer-wrap .answer-wrap > img {
    height: 65%;
  }

  .six-answers .answer-wrap:last-of-type,
  .six-answers .answer-wrap:nth-of-type(3) {
    margin-right: 0 !important;
  }
  /* End Six Answers */

  /* Ten ANSWERS */
  .form-wrap.ten-answers .single-choice-answer-wrap {
    width: 80%;
    padding: 0 !important;
  }

  .form-wrap.ten-answers .single-choice-answer-wrap .answer-wrap {
    width: 15%;
    height: 45%;
    margin-right: 5%;
  }

  .form-wrap.ten-answers .single-choice-answer-wrap .answer-wrap > p {
    font-size: 18px;
  }

  .form-wrap.ten-answers .single-choice-answer-wrap .answer-wrap > img {
    height: 60%;
  }

  .form-wrap.ten-answers .multi-choice-answer-wrap {
    width: 80%;
    padding: 0;
    justify-content: center;
  }

  .form-wrap.ten-answers .multi-choice-answer-wrap .answer-wrap {
    width: 15%;
    margin-right: 5%;
  }

  .form-wrap.ten-answers .multi-choice-answer-wrap .answer-wrap > p {
    font-size: 18px;
  }

  .ten-answers .answer-wrap:last-of-type,
  .ten-answers .answer-wrap:nth-of-type(5) {
    margin-right: 0 !important;
  }

  @media (min-height: 600px) and (max-height: 700px) {
    .form-wrap.ten-answers .multi-choice-answer-wrap {
      grid-row-gap: 25px;
    }

    .form-wrap.ten-answers .multi-choice-answer-wrap .image-wrap > svg {
      height: 90px;
    }
  }

  /* END TEN ANSWERS */

  .form-wrap.many-answers {
    height: 87%;
  }

  .form-wrap.many-answers .answers-wrap {
    display: flex;
    align-items: center;
  }
}

.footer-center-actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding-bottom: 10px;
}

@media (max-width: 1076px) {
  .form-wrap {
    width: 87%;
  }
}

@media (max-height: 730px) {
  .form-wrap .form-actions {
    margin-top: 12px;
  }
}

.footer-actions {
  width: 100%;
}

.footer-side-actions {
  display: flex;
  justify-content: space-around;
}

.hide-on-mobile {
  display: none;
}

.hide-on-desktop {
  display: flex;
}

.footer-side-actions:last-child {
  margin-top: 20px;
}

@media (max-height: 580px) {
  .form-wrap > .header-wrap {
    min-height: 48px;
  }

  .footer-side-actions:last-child {
    margin-top: 10px;
  }

  .form-wrap {
    padding-bottom: 2.5vh;
  }
}

@media (min-width: 640px) {
  .title-wrap {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 25px;
  }

  .question-title {
    padding: 0 100px;
  }

  .title-wrap button {
    position: relative;
    left: 36px;
    cursor: pointer;
    border: 2px solid var(--oslo-gray);
    background: none;
    width: 32px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-wrap svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .question-title {
    padding-bottom: 0;
  }

  .answers-wrap {
    margin-top: auto;
  }

  .footer-actions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 25px 0;
    background-color: white;
  }

  .footer-side-actions {
    width: 106px;
  }

  .answers-progress-wrap {
    position: relative;
    top: 45px;
  }

  .footer-center-actions {
    padding-bottom: 0;
  }

  .footer-center-actions .primary-button {
    margin-top: 0 !important;
  }

  .hide-on-mobile {
    display: flex;
  }
  
  .hide-on-desktop {
    display: none;
  }

  .footer-side-actions:last-child {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .footer-center-actions .primary-button {
    width: 170px
  }
}
