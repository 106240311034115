@import 'question-card.css';
@import 'navigator.css';

:root {
  --apricot: #F4A27E;
  --outer-space: #1F282C;
  --oslo-gray: #90969A;
  --gallery: #EEEEEE;
  --brackets-color: var(--oslo-gray);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow: hidden;
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
}

input, textarea, select, button {
  font-family: inherit;
}

#root {
  min-width: 233px;
  width: 100%;
  min-height: fit-content;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  background-color: var(--gallery);
}

.loading_screen {
  z-index: 1;
  left: 0;
  top: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.loading_content {
  position: relative;
  top: 50%;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background: var(--oslo-gray);
}

::-webkit-scrollbar-track {
  background: #d4d4d4;
}

::-webkit-scrollbar-thumb:hover {
  background: #5e5a5a;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.Toastify__toast-container--top-center.toaster-wrap {
  width: 90%;
  left: 50%;
  transform: translate(-50%, 3vh);
}

.toaster-wrap .Toastify__toast--warning {
  background: #f3795b;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.Toastify__toast-body {
  text-align: center;
  font-weight: bold;
}

.Toastify__toast-body > .toast-message-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  text-align: left;
}

.toast-message-wrap > svg {
  padding: 5px;
}

.toast-message-wrap > p {
  margin-left: 16px;
  margin-right: 13px;
  text-align: center;
  font-family: 'Roboto';
  font-weight: normal;
}

.fs-15px {
  font-size: 15px !important;
}

@media (min-width: 640px) {
  .Toastify__toast-container--top-center.toaster-wrap {
    width: 30%;
    transform: translate(-50%, 2vh);
  }
}

@media (min-width: 1200px) {
  .toast-message-wrap > svg {
    padding: 8px;
  }
}
