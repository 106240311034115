.question-navigator {
  margin: 20px auto;
  padding: 10px;
  text-align: center;
}

.question-navigator-button {
  padding: 10px;
  margin: 5px;
  text-align: center;
  background: none;
  border: solid 1px black;
}

.question-navigator-button:hover {
  background-color: rgb(0, 0, 0, 0.1);
  cursor: pointer;
}

.question-navigator-button-active {
  background-color: rgb(215, 235, 255);
}

.question-navigation__prev-next-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.question-navigation__prev-next {
  padding: 10px;
  width: 50%;
  background: rgb(233, 233, 233);
  border: none;
}

.question-navigation__finish {
  width: 100%;
}

.question-navigation__prev-next:hover {
  cursor: pointer;
  background: rgb(218, 218, 218);
}

.question-navigation__prev {
  border-right: solid 0.5px black;
}

.question-navigation__next {
  border-left: solid 0.5px black;
}
