.loading-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--gallery);
  z-index: 2;
}

.loading-wrap > p {
  font-family: Montserrat;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--outer-space);
  text-align: center;
  position: absolute;
  top: 15%;
  padding: 10px;
}

.loading-content {
  background-repeat: no-repeat;
  background-position: bottom left;
  height: 100%;
  transform: scaleX(-1);
  background-size: contain;
  width: 100%;
}

.loading-wrap svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50% !important;
  height: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.loading-wrap svg * {
  stroke: var(--apricot);
  stroke-width: 3px;
}

@media only screen and (min-width: 768px) {
  .loading-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--gallery);
  }

  .loading-wrap > p {
    font-size: 24px;
  }

  .loading-wrap > div > svg {
    width: 100% !important;
  }

  .loading-content {
    background-position: top center;
    background-size: cover;
    transform: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
