.primary-button {
  text-align: center;
  background: var(--apricot);
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  border: none;
  outline: 0;
  padding: 10px 25px;
  margin-top: 10px;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary-button:focus {
  box-shadow: 0px 0px 2px 1px var(--apricot);
}
