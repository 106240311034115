@media only screen and (min-width: 768px) {
  .modal-content.description-modal-content
    > .description-itens
    > .description-item:nth-of-type(even) {
    margin-right: 0;
  }

  .description-item.empty {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-height: 800px) {
  .desktop-description-item > .header > .img-wrap {
    width: 60px;
    height: 60px;
  }

  .header > .title-wrap > h2 {
    font-size: 16px;
  }

  .desktop-description-item > .content {
    margin-top: 8px;
  }

  .desktop-description-item > .content > p {
    font-size: 12px;
  }
}
