.multi-choice-answer-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  width: 100%;
  grid-row-gap: 25px;
}

@media (max-width: 340px)  {
  .multi-choice-answer-wrap {
    grid-row-gap: 20px;
  }
}

@media (min-width: 640px) {
  .multi-choice-answer-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    flex-wrap: wrap;
    padding-right: 0 !important;
    grid-row-gap: 0;
  }
}
